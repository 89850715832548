import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { ContentContainer } from '../styles/styled';

const inquiry = () => {
  return (
    <Layout>
      <SEO title="Inquiry" />
      <ContentContainer>
        <Typography variant="h2" gutterBottom>
          <FormattedHTMLMessage id="inquiryTitle" />
        </Typography>
        <Typography variant="body1">
          <FormattedHTMLMessage id="inquiryText" />
        </Typography>
      </ContentContainer>
    </Layout>
  );
};

export default inquiry;
